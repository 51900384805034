import React, { useState } from "react";
import axios from "axios";
import { Button, CircularProgress, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./index";

export const Upload = () => {
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<any>([]);

  const handleFileSelect = (e) => {
    const arr = Array.from(e.target.files);
    setUploadedFiles(arr);
    arr.forEach((file) => {
      console.log(file);
    });
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    if (!uploadedFiles.length) return;

    const formData = new FormData();
    uploadedFiles.forEach((file) => formData.append("file", file));

    try {
      // Upload the file
      setIsLoading(true);
      await axios
        .post("/api/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setResult(res);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert(error.response?.data?.error || "Error uploading file");
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div
          style={{
            paddingTop: 40,
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "95%",
              maxWidth: 800,
              textAlign: "left",
            }}
          >
            <Typography variant="h5" style={{ marginBottom: "12px" }}>
              File Upload
            </Typography>
            <div
              style={{
                paddingTop: 0,
                paddingBottom: 30,
                display: "flex",
                flexFlow: "column",
                gap: "12px",
              }}
            >
              <Button variant="outlined" color="primary" component="label">
                Select File
                <input type="file" hidden onChange={handleFileSelect} />
              </Button>
              <div>
                {uploadedFiles ? (
                  Array.from(uploadedFiles).map((file: any) => (
                    <Typography
                      style={{
                        maxWidth: 900,
                        whiteSpace: "pre-line",
                      }}
                      variant="body2"
                    >
                      {file.name}
                    </Typography>
                  ))
                ) : (
                  <></>
                )}
              </div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleFileUpload}
              >
                Upload
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: 20,
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : result ? (
                <div>
                  {result?.data?.texts?.map((text: any) => (
                    <Typography
                      style={{
                        maxWidth: 900,
                        whiteSpace: "pre-line",
                      }}
                      variant="body2"
                    >
                      {text}
                    </Typography>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};
